export function Pagination({ currentPage, totalPages, loadPage }) {

  return (
    <div className="w-full border-y py-4 flex justify-center items-center">
      <nav className="flex space-x-2" aria-label="Pagination">

        {/* Left Pointer */}
        <button
          onClick={() => loadPage(currentPage - 1)}
          className={`${currentPage === 1 ? 'cursor-default opacity-50' : 'hover:bg-gray-400'} relative inline-flex items-center px-3 py-1 rounded-l text-black bg-gray-200`}
        >
          «
        </button>

        {/* Page Numbers */}
        <button key="page-1" onClick={() => loadPage(1)} className={`${currentPage === 1 ? 'bg-rose-400 text-white' : 'bg-white hover:bg-gray-200'} relative inline-flex items-center px-3 py-1 rounded text-black`}>
          1
        </button>

        {/* Ellipsis if current page - 1 > 2 (because page 1 is already shown) */}
        {currentPage - 1 > 2 && <span className="px-3 py-1">...</span>}

        {/* Show 2 pages before current page, but not the first and last pages */}
        {currentPage > 3 && (
          <button key={`page-${currentPage - 2}`} onClick={() => loadPage(currentPage - 2)} className="bg-white hover:bg-gray-200 relative inline-flex items-center px-3 py-1 rounded text-black">
            {currentPage - 2}
          </button>
        )}

        {currentPage > 2 && (
          <button key={`page-${currentPage - 1}`} onClick={() => loadPage(currentPage - 1)} className="bg-white hover:bg-gray-200 relative inline-flex items-center px-3 py-1 rounded text-black">
            {currentPage - 1}
          </button>
        )}

        {/* Current Page if it's not the first or last */}
        {currentPage !== 1 && currentPage !== totalPages && (
          <button key={`page-${currentPage}`} onClick={() => loadPage(currentPage)} className="bg-rose-400 text-white relative inline-flex items-center px-3 py-1 rounded">
            {currentPage}
          </button>
        )}

        {/* Show 2 pages after current page, but not the first and last pages */}
        {currentPage < totalPages - 1 && (
          <button key={`page-${currentPage + 1}`} onClick={() => loadPage(currentPage + 1)} className="bg-white hover:bg-gray-200 relative inline-flex items-center px-3 py-1 rounded text-black">
            {currentPage + 1}
          </button>
        )}

        {currentPage < totalPages - 2 && (
          <button key={`page-${currentPage + 2}`} onClick={() => loadPage(currentPage + 2)} className="bg-white hover:bg-gray-200 relative inline-flex items-center px-3 py-1 rounded text-black">
            {currentPage + 2}
          </button>
        )}

        {/* Ellipsis if current page + 1 < totalPages - 1 */}
        {currentPage + 1 < totalPages - 1 && <span className="px-3 py-1">...</span>}

        {/* Always show the last page if it's not page 1 */}
        {totalPages > 1 && (
          <button key={`page-${totalPages}`} onClick={() => loadPage(totalPages)} className={`${currentPage === totalPages ? 'bg-rose-400 text-white' : 'bg-white hover:bg-gray-200'} relative inline-flex items-center px-3 py-1 rounded text-black`}>
            {totalPages}
          </button>
        )}

        {/* Right Pointer */}
        <button
          onClick={() => loadPage(currentPage + 1)}
          className={`${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''} relative inline-flex items-center px-3 py-1 rounded-r text-black bg-gray-200 hover:bg-gray-400`}
        >
          »
        </button>

      </nav>
    </div>
  );
}
