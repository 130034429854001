export function Svg({ record }) {
    return (
      <div key={record.id} className="cursor-pointer hover:bg-neutral-50 rounded p-4 flex flex-col">
        <div
          dangerouslySetInnerHTML={{ __html: record.content }}
          className="svg-container"
        />
        <div className="flex-grow" />
        <button className="w-full mt-4 bg-neutral-200 rounded py-1 font-bold text-xs text-black">SVG</button>
      </div>
    );
  }
