import { useEffect, useState } from 'react';
import { useAccessKey } from './AccessKeyContext';

import Premium from '../components/Premium';

export const useLocalStorageKey = () => {
  const [key, setKey] = useState(localStorage.getItem('access_key') || null);

  const saveKey = (newKey) => {
    setKey(newKey);
    localStorage.setItem('access_key', newKey);
  }

  return [key, saveKey];
}

export const ProtectedComponent = ({ children }) => {
  const { isValidKey, validateKey } = useAccessKey();
  const [storedKey, saveKey] = useLocalStorageKey();

  const urlParams = new URLSearchParams(window.location.search);
  const accessKey = urlParams.get('access_key');

  useEffect(() => {
    if (storedKey) {
      validateKey(storedKey);
    } else if (accessKey) {
      saveKey(accessKey);
      validateKey(accessKey);
    }
  }, [storedKey, accessKey, validateKey, saveKey]);

  if (isValidKey) {
    return children;
  }

  return <Premium />
}


export default ProtectedComponent;
