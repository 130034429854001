export function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
}

export function rgbToHex(r, g, b) {
    return '#' + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase();
}

export function adjustColor(originalColor, targetColor) {
    const [or, og, ob] = hexToRgb(originalColor);
    const [tr, tg, tb] = hexToRgb(targetColor);

    // Calculate the adjustment based on the difference
    const ar = (tr - or) * 0.5;
    const ag = (tg - og) * 0.5;
    const ab = (tb - ob) * 0.5;

    // Adjust the original color
    const newR = Math.round(or + ar);
    const newG = Math.round(og + ag);
    const newB = Math.round(ob + ab);

    return rgbToHex(newR, newG, newB);
}

export function generateRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
}
