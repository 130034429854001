import React from "react";

const Steps = () => {

  return (
    <section id="steps" class="text-gray-600 body-font text-left">
  <div class="container px-5 py-12 mx-auto flex flex-wrap">
    <div class="flex flex-wrap w-full items-center">
      
      <img class="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:order-2" src="img/usage.gif" alt="step" />

      <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6 md:order-1">
      <h1 className="mt-12 md:mt-0 sm:text-3xl text-2xl font-medium title-font text-black mb-8">How it works</h1>

        <div class="flex relative pb-12">
          <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-10 h-10 rounded-full bg-orange-500 inline-flex items-center justify-center text-white relative z-9">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 50 50">
              <path fill="white" d="M 21 3 C 11.6 3 4 10.6 4 20 C 4 29.4 11.6 37 21 37 C 24.354553 37 27.47104 36.01984 30.103516 34.347656 L 42.378906 46.621094 L 46.621094 42.378906 L 34.523438 30.279297 C 36.695733 27.423994 38 23.870646 38 20 C 38 10.6 30.4 3 21 3 z M 21 7 C 28.2 7 34 12.8 34 20 C 34 27.2 28.2 33 21 33 C 13.8 33 8 27.2 8 20 C 8 12.8 13.8 7 21 7 z"></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="font-bold title-font text-sm text-black mb-1 tracking-wider">FIND AN EMOJI</h2>
            <p class="leading-relaxed">Begin by choosing the base emoji that fits seamlessly into your web design. Whether you're creating a playful interface or a sophisticated layout, our diverse emoji library has you covered.</p>
          </div>
        </div>
        <div class="flex relative pb-12">
          <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-10 h-10 rounded-full bg-orange-500 inline-flex items-center justify-center text-white relative z-9">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="font-bold title-font text-sm text-black mb-1 tracking-wider">CUSTOMIZE COLOR</h2>
            <p class="leading-relaxed">Tailor your emoji's appearance with precision. Choose from RGB or HSL color customization to set the perfect mood, and fine-tune stroke and fill details to make your emoji uniquely yours.</p>
          </div>
        </div>
        <div class="flex relative">
          <div class="flex-shrink-0 w-10 h-10 rounded-full bg-orange-500 inline-flex items-center justify-center text-white relative z-9">
            <svg viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g stroke-width="0"></g>
              <g stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g id="Interface / Download">
                  <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="font-bold title-font text-sm text-black mb-1 tracking-wider">DOWNLOAD SVG</h2>
            <p class="leading-relaxed">Bring your web design to life effortlessly. Save and implement your customized SVG emojis to enhance user engagement and convey emotions uniquely.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
};

export default Steps;
