import { useNavigate } from "react-router-dom";

import { Svg } from "./Svg";

export function ItemList({ items, isLoading }) {

  const navigate = useNavigate();

  return (
    <div className="w-full my-6">
      <div className={`grid grid-cols-6 gap-1 lg:grid-cols-12 sm:gap-6`}>
        {isLoading ? (
          <div className="col-span-6 lg:col-span-12 flex justify-center items-center">
            <svg className="animate-spin w-12 h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 2.99988V5.99988M12 20.9999V17.9999M4.20577 16.4999L6.80385 14.9999M21 11.9999H18M16.5 19.7941L15 17.196M3 11.9999H6M7.5 4.20565L9 6.80373M7.5 19.7941L9 17.196M19.7942 16.4999L17.1962 14.9999M4.20577 7.49988L6.80385 8.99988" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          </div>
        ) : (
          items.map(record => (
            <button onClick={() => navigate(`/svg/${record.id}`)}><Svg key={record.id} record={record} /></button>
          ))
        )}
      </div>
    </div>
  );
}
