import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Demo from './components/Demo';
import Steps from './components/Steps';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Modal from './components/Modal';

import { AccessKeyProvider, useAccessKey } from './access/AccessKeyContext';
import { ProtectedComponent, useLocalStorageKey } from './access/ProtectedComponent';

function MainContent() {
  const { isValidKey, validateKey } = useAccessKey();
  const [storedKey, saveKey] = useLocalStorageKey();

  const urlParams = new URLSearchParams(window.location.search);
  const accessKey = urlParams.get('access_key');

  useEffect(() => {
    if (storedKey) {
      validateKey(storedKey);
    } else if (accessKey) {
      saveKey(accessKey);
      validateKey(accessKey);
    }
  }, [storedKey, accessKey, validateKey, saveKey]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <Header showOptions={!isValidKey ? true : false} />
            <Demo />
            {!isValidKey && (
              <>
                <Steps />
                <Pricing />
                <Testimonials />
              </>
            )}
            <Contact />
            <Footer />
          </div>
        } />
        <Route path="/svg/:id" element={
          <div className="App flex flex-col h-screen">
            <Header />
            <ProtectedComponent>
              <Modal />
            </ProtectedComponent>
            <Footer />
          </div>
        } />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <AccessKeyProvider>
      <MainContent />
    </AccessKeyProvider>
  );
}

export default App;
