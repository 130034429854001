import { createContext, useContext, useState } from 'react';

const AccessKeyContext = createContext();

export const useAccessKey = () => {
  return useContext(AccessKeyContext);
}

export const AccessKeyProvider = ({ children }) => {
  const [isValidKey, setIsValidKey] = useState(false);
  
  const validateKey = async (key) => {
    // Call your backend to verify the key
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
    const url = `${apiUrl}/api/payments/verify`

    const options = {
      method: 'POST',
      body: key,
      redirect: 'follow'
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (data.valid) {
      setIsValidKey(true);
      localStorage.setItem('access_key', key);
      return true;
    } else {
      setIsValidKey(false);
      return false;
    }
  }

  return (
    <AccessKeyContext.Provider value={{ isValidKey, validateKey }}>
      {children}
    </AccessKeyContext.Provider>
  );
}
