import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccessKey } from "../access/AccessKeyContext";

const CHECKOUT_URL = process.env.REACT_APP_STRIPE_CHECKOUT_URL;

const Header = ({ showOptions }) => {
  const { isValidKey, validateKey } = useAccessKey();
  const navigate = useNavigate();

  useEffect(() => {
    const storedKey = localStorage.getItem('accessKey');
    if (storedKey) {
      validateKey(storedKey);
    }
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="bg-white text-gray-600 body-font w-full border-b">
      <div className="container mx-auto flex flex-wrap p-5 items-center justify-between">
        {/*... Logo button code ...*/}
        <button className="flex title-font font-medium items-center text-gray-900" onClick={() => navigate("/")}>
          <svg viewBox="0 0 24 24" class="w-8 md:w-10 h-8 md:h-10 text-white p-1 bg-blue-500 rounded-full" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.39747 15.5534C8.64413 15.2206 9.11385 15.1508 9.44661 15.3975C10.175 15.9373 11.0541 16.25 12 16.25C12.9459 16.25 13.825 15.9373 14.5534 15.3975C14.8862 15.1508 15.3559 15.2206 15.6025 15.5534C15.8492 15.8862 15.7794 16.3559 15.4466 16.6025C14.4742 17.3233 13.285 17.75 12 17.75C10.715 17.75 9.5258 17.3233 8.55339 16.6025C8.22062 16.3559 8.15082 15.8862 8.39747 15.5534ZM16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5ZM9 12C9.55228 12 10 11.3284 10 10.5C10 9.67157 9.55228 9 9 9C8.44772 9 8 9.67157 8 10.5C8 11.3284 8.44772 12 9 12Z" fill="white"></path> </g></svg>
          <span className="ml-2 md:ml-3 text-xl md:text-2xl"><span className="font-light">svg</span>emoji</span>
        </button>

        <nav className="hidden md:flex flex-grow items-center text-base justify-center">
          {showOptions && (
            <>
              <button className="hover:text-orange-500 cursor-pointer mx-4" onClick={() => scrollToSection("steps")}>How it works</button>
              <button className="hover:text-orange-500 cursor-pointer mx-4" onClick={() => scrollToSection("testimonials")}>Testimonials</button>
              <button className="hover:text-orange-500 cursor-pointer mx-4" onClick={() => scrollToSection("contact")}>Contact</button>
            </>
          )}
        </nav>

        <div className="flex items-center">
          {
            !isValidKey ? (
              <a href={CHECKOUT_URL} className="hidden md:inline-flex items-center bg-lime-300 border-0 py-2 px-4 font-bold text-black focus:outline-none hover:bg-lime-600 hover:text-white rounded text-base mr-3">
                <span className="hidden md:block">Lifetime access for $49</span>
                {/*... Arrow icon ...*/}
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            ) : (
              <p className="hidden md:inline-flex bg-gray-200 py-2 px-4 text-black rounded">
                {/*... Star icon ...*/}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mt-1 mr-2">
                    <path fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clipRule="evenodd" />
                  </svg>
                <span className="font-bold">Premium</span>
              </p>
            )
          }

          <div className="md:hidden flex items-center">
              {!isValidKey ? (
                <>
              <a href={CHECKOUT_URL} className="inline-flex items-center bg-lime-300 border-0 py-1 px-2 font-bold text-black focus:outline-none hover:bg-lime-600 hover:text-white rounded text-base mr-1">
                <span>Lifetime $49</span>
                {/*... Arrow icon ...*/}
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a> 
                          <button onClick={toggleNav} className="px-1 py-1 rounded bg-gray-200">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                          </svg>
                        </button>
                        </>
              ) : (
                <p className="inline-flex bg-gray-200 py-1 px-2 text-black rounded mr-3">
                  {/*... Star icon ...*/}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mt-1 mr-1">
                    <path fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clipRule="evenodd" />
                  </svg>
                <span className="font-bold">Premium</span>
              </p>
              )
            }
          </div>
        </div>

        {isNavOpen && (
          <div className="w-full mt-4 md:hidden">
            <nav>
              {/*... Your mobile nav buttons ...*/}
              <nav>
                <button className="hover:text-orange-500 cursor-pointer mr-4" onClick={() => scrollToSection("steps")}>How it works</button>
                <button className="hover:text-orange-500 cursor-pointer mr-4" onClick={() => scrollToSection("testimonials")}>Testimonials</button>
                <button className="hover:text-orange-500 cursor-pointer mr-4" onClick={() => scrollToSection("contact")}>Contact</button>
              </nav>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};
export default Header;