import React, { useState } from "react";

const Contact = () => {
  const [response, setResponse] = useState(null);

  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = async () => {
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/api/email/contact`;
    const headers = {
      'Content-Type': 'application/json'
    };
  
    // Modify the body to match the FastAPI endpoint's expected format
    const body = JSON.stringify({
      name: formData.name,
      email: formData.email,
      message: formData.message,
    });
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body
      });
      const data = await response.json();
      setResponse("Got your message and will get back to you ASAP!");
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } catch (error) {
      setResponse("Hmmm, something went wrong.")
    }
  };

  return (
    <section id="contact" class="text-gray-600 body-font relative mt-16">
      <div class="container px-5 py-4 mx-auto">
        <div class="flex flex-col text-center w-full mb-4 md:mb-12">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-black">Have questions? Contact us!</h1>
        </div>
        <div class="lg:w-1/2 md:w-2/3 mx-auto text-left">
          <div class="flex flex-wrap">
            <div class="p-2 w-1/2">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                <input value={formData.name} onChange={handleInputChange} type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white text-base outline-none text-gray-700 py-1 px-3 leading-8" required/>
              </div>
            </div>
            <div class="p-2 w-1/2">
              <div class="relative">
                <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                <input value={formData.email} onChange={handleInputChange} type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white text-base outline-none text-gray-700 py-1 px-3 leading-8" required />
              </div>
            </div>
            <div class="p-2 w-full">
              <div class="relative">
                <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                <textarea value={formData.message} onChange={handleInputChange} id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white text-base outline-none text-gray-700 py-1 px-3 leading-8 h-32 resize-none" required></textarea>
              </div>
            </div>
            <div class="p-2 mb-2 w-full">
              {response && <span className="text-sm text-emerald-500">{response}</span>}
            </div>
            <div class="p-2 w-full">
              <button onClick={sendEmail} type="submit" class="flex mx-auto text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-700 rounded font-bold text-lg">Send</button>
            </div>
            <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center text-neutral-500">
              <a href="mailto:help@svgemoji.com" class="text-blue-500 hover:text-blue-700">help@svgemoji.com</a>
              <p class="leading-normal my-5">
                Made with ❤️ by <a className="hover:text-lime-600" href="https://twitter.com/mitchbregs" target="_blank">@mitchbregs</a>
              </p>
              <span class="inline-flex">
                <a class="text-gray-500">
                  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                <a class="ml-4 text-gray-500">
                  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </a>
                <a class="ml-4 text-gray-500">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
                <a class="ml-4 text-gray-500">
                  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                  </svg>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
