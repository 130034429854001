import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { adjustColor, generateRandomColor } from "../utils";
import SvgComponent from './SvgComponent';
import './Modal.css';


function Modal() {

  const INITIAL_STATE = {
    isLoading: true,
    id: "",
    query: "",
    content: "",
    color: "#FFDE34",
    isColored: false,
  };
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

  const { id } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState(INITIAL_STATE)
  const [color, setColor] = useState('#ffde34');

  const [selectedPath, setSelectedPath] = useState(null);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [colorProps, setColorProps] = useState({
    strokeColor: '#ffffff',
    strokeOpacity: 1,
  });

  const [isLightBackground, setIsLightBackground] = useState(true);

  const fetchSVGData = (id) => {
    setState(prev => ({ ...prev, isLoading: true }));

    const url = `${apiUrl}/api/svg/${id}/`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setState({
          isLoading: null,
          id: data.id,
          query: data.query,
          content: data.content,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setState(prev => ({ ...prev, isLoading: null }));
      });
  };

  useEffect(() => {
    fetchSVGData(id);
  }, []);

  const handleClose = () => navigate(-1)

  const randomizeColors = () => {
    const svgContainer = document.querySelector('.svg-modal-container > svg');
    const elements = svgContainer.querySelectorAll('path, circle, rect, line, ellipse');
    elements.forEach(el => {
      handleSvgAttributeChange('fill', generateRandomColor(), 'data-original-fill-grayscale', el);
    });
  };
  const changeColors = e => {
    setColor(e.target.value);
    handleSvgAttributeChange('fill', e.target.value, 'data-original-fill-grayscale');
    handleSvgAttributeChange('stroke', e.target.value, 'data-original-stroke-grayscale');
  };
  const resetColors = () => {
    const svgContainer = document.querySelector('.svg-modal-container > svg');
    const elements = svgContainer.querySelectorAll('path, circle, rect, line, ellipse');
    elements.forEach(el => {
      ['data-original-fill', 'data-original-stroke'].forEach(attr => {
        if (el.hasAttribute(attr)) {
          el.setAttribute(attr.replace('data-original-', ''), el.getAttribute(attr));
        }
      });
    });
    setState((prev) => ({ ...prev, content: svgContainer.outerHTML }));
  };

  // These functions could be used to handle additional logic 
  // whenever the path or colorProps change.
  const handlePathSelect = (path) => {
    setSelectedPath(path);
  };

  const handleColorPropsChange = (props) => {
    setColorProps(props);
  };

  const handleSvgAttributeChange = (attribute, value, attributeGrayscale, specificElement = null) => {
    const svgContainer = document.querySelector('.svg-modal-container > svg');
    const elements = specificElement ? [specificElement] : svgContainer.querySelectorAll('path, circle, rect, line, ellipse');
    elements.forEach(el => {
      if (el.hasAttribute(attributeGrayscale)) {
        const currentColor = el.getAttribute(attributeGrayscale);
        const adjustedColor = adjustColor(currentColor, value);
        el.setAttribute(attribute, adjustedColor);
      }
    });
    setState((prev) => ({ ...prev, content: svgContainer.outerHTML }));
  };

  const copySVGToClipboard = () => {
    navigator.clipboard.writeText(state.content)
  }

  const downloadSVG = () => {
    const blob = new Blob([state.content], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${state.query}.svg`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  console.log(state)
  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 grid-rows-2 md:grid-rows-none h-full grow overflow-auto">
      <div className="bg-custom-light flex items-center justify-center p-4 sm:p-6 w-full h-full">
        {state.isLoading ? (
          <div className="col-span-6 lg:col-span-12 flex justify-center items-center">
            <svg className="animate-spin w-12 h-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 2.99988V5.99988M12 20.9999V17.9999M4.20577 16.4999L6.80385 14.9999M21 11.9999H18M16.5 19.7941L15 17.196M3 11.9999H6M7.5 4.20565L9 6.80373M7.5 19.7941L9 17.196M19.7942 16.4999L17.1962 14.9999M4.20577 7.49988L6.80385 8.99988" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          </div>
        ) : (<SvgComponent
          svgCode={state.content}
          selectedPath={selectedPath}
          onPathSelect={handlePathSelect}
          colorProps={colorProps}
          onColorPropsChange={handleColorPropsChange}
          onPathHover={(index) => setHighlightedLine(index)}
        />)}
      </div>
      <div className="relative bg-white p-4 sm:p-6 overflow-y-auto w-full h-full">
        {state.isLoading ? (
          ''
        ) : (
          <>
            {/* Name and Close button container */}
            <div className="flex justify-between items-center p-6">
              <h1 className="text-xl font-bold">{state.query}</h1>
              {/* Close button */}
              <button type="button" onClick={handleClose} className="text-gray-400 bg-transparent rounded text-sm w-8 h-8 inline-flex justify-center items-center" data-modal-hide="defaultModal">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <hr></hr>
            <div className="flex items-center p-6 space-x-2">
              {/* Filter buttons */}
              <div className="flex items-center space-x-2">
                <button onClick={resetColors} className="inline-flex items-center bg-neutral-200 hover:bg-neutral-300 font-bold py-1 px-3 focus:outline-none rounded text-black">
                  Reset
                </button>
                <button onClick={randomizeColors} className="inline-flex items-center font-bold bg-gradient-to-r from-orange-500 to-purple-500 hover:from-green-400 hover:to-blue-500 py-1 px-3 text-white focus:outline-none rounded">
                  Random
                </button>
                <input value={color} onChange={changeColors} type="color" className="cursor-pointer h-8 sm:w-9 rounded px-1 bg-gray-100 bg-opacity-50 border border-gray-300 focus:border-blue-500" />
              </div>
            </div>
            <hr />
            <div className="p-6 text-left overflow-y-auto h-4/6">
              {state.content.split('\n')
                .filter(line =>
                  ['<path', '<rect', '<circle'].some(tag => line.includes(tag))
                )
                .map((line, index) => (
                  <div
                    key={index}
                    className={`my-1 text-xs w-full overflow-hidden whitespace-nowrap py-1 px-1 bg-gray-100 rounded text-ellipsis ${highlightedLine === index ? 'bg-orange-200' : ''}`}
                  >
                    {line}
                  </div>
                ))
              }
            </div>
            <hr />
            <div className="flex items-center p-6 space-x-2">
              <button onClick={copySVGToClipboard} data-modal-hide="defaultModal" type="button" className="bg-blue-500 text-white py-1 px-3 rounded font-bold hover:bg-blue-700">Copy SVG</button>
              <button onClick={downloadSVG} type="button" className="bg-green-500 text-white py-1 px-3 rounded font-bold hover:bg-green-700">Download SVG</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;
