export function Filters({
  color, handleColorChange, randomizeColors, resetColors, isColored,
  searchTerm, handleSearchTermChange, handleSearchButtonClick, resetSearch, isLoading
}) {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-full border-y py-4">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2 w-full md:w-auto">
        <div className="flex items-center w-full space-x-2">
          <SearchInput searchTerm={searchTerm} handleSearchTermChange={handleSearchTermChange} isLoading={isLoading} handleSearchButtonClick={handleSearchButtonClick} />
          <ColorPicker color={color} onChange={handleColorChange} />
          <RandomizeButton onClick={randomizeColors} />
        </div>
        <div className="flex w-full space-x-2">
          <ResetButton onClick={resetSearch} label={searchTerm} />
          <ResetButton onClick={resetColors} label={isColored ? color : ''} />
        </div>
      </div>
    </div>
  );
}

const SearchInput = ({ searchTerm, handleSearchTermChange, isLoading, handleSearchButtonClick }) => (
  <div className="w-full flex justify-center items-center">
    <input 
      type="text" 
      placeholder="Search" 
      value={searchTerm} 
      onChange={handleSearchTermChange} 
      className="flex-grow md:flex-none h-8 bg-white rounded-l border border-r-0 border-gray-300 focus:border-blue-500 focus:bg-white text-base outline-none text-gray-700 py-1 px-3 leading-8 md:w-96" 
    />
    {isLoading ? (
      <LoadingButton />
    ) : (
      <SearchButton onClick={handleSearchButtonClick} />
    )}
  </div>
);

const LoadingButton = () => (
  <button className="bg-gray-500 text-white py-1 px-3 rounded-r">
    <LoadingIcon />
  </button>
);

const SearchButton = ({ onClick }) => (
  <button onClick={onClick} className="bg-blue-500 text-white hover:bg-blue-700 py-1 px-3 rounded-r">
    Search
  </button>
);

const ColorPicker = ({ color , onChange}) => (
  <label style={{ backgroundColor: color }} className='w-8 h-8 rounded'>
    <input type="color" onChange={onChange} value={color} className="invisible cursor-pointer rounded bg-gray-100 bg-opacity-50 border border-gray-300 focus:border-blue-500" />
  </label>
);

const ResetButton = ({ onClick, label }) => (
  <button onClick={onClick} className={`${!label ? 'hidden' : ''} inline-flex items-center bg-gray-200 hover:bg-gray-400 py-1.5 px-3 outline-none rounded text-black`}>
    <span className="text-sm mr-2">{label}</span>
    <CrossIcon />
  </button>
);

const CrossIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

);

const LoadingIcon = () => (
  <svg className="animate-spin w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M12 2.99988V5.99988M12 20.9999V17.9999M4.20577 16.4999L6.80385 14.9999M21 11.9999H18M16.5 19.7941L15 17.196M3 11.9999H6M7.5 4.20565L9 6.80373M7.5 19.7941L9 17.196M19.7942 16.4999L17.1962 14.9999M4.20577 7.49988L6.80385 8.99988" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </g>
  </svg>
);

const RandomizeButton = ({ onClick }) => (
  <button onClick={onClick} className="h-8 w-8 inline-flex items-center font-bold bg-gradient-to-r from-red-400 via-lime-400 to-blue-400 hover:bg-gradient-to-l px-2 text-white focus:outline-none hover:bg-neutral-200 rounded text-base">
    <RandomizeIcon />
  </button>
);

const RandomizeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
  </svg>
);
