import { useEffect, useRef } from 'react';
import './SvgComponent.css'

function SvgComponent({ svgCode, selectedPath, onPathSelect, colorProps, onColorPropsChange, onPathHover }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const handlePathClick = (event) => {
      const target = event.target;
      const isPath = target.tagName.toLowerCase() === 'path';
      if (isPath) {
        const color = getAttributeWithFallback(target, ['stroke', 'fill'], '#ffffff');
        const opacity = getAttributeWithFallback(target, ['stroke-opacity', 'fill-opacity'], '1');

        onPathSelect(target);
        onColorPropsChange({
          strokeColor: color,
          strokeOpacity: parseFloat(opacity)
        });
      }
    };

    const container = containerRef.current;
    container.addEventListener('click', handlePathClick);

    return () => {
      container.removeEventListener('click', handlePathClick);
    };
  }, [onPathSelect, onColorPropsChange]);

  useEffect(() => {
    const svgContainer = containerRef.current;
    const paths = svgContainer.querySelectorAll('path');

    const mouseOverListeners = [];
    const handleMouseOver = (index) => {
      const listener = () => {
        if (onPathHover) {
          onPathHover(index);
        }
      };
      mouseOverListeners.push(listener);
      return listener;
    };

    const handleMouseOut = () => {
      if (onPathHover) {
        onPathHover(null); // clear the highlight
      }
    };

    paths.forEach((path, index) => {
      path.addEventListener('mouseover', handleMouseOver(index));
      path.addEventListener('mouseout', handleMouseOut);
    });

    return () => {
      paths.forEach((path, index) => {
        path.removeEventListener('mouseover', mouseOverListeners[index]);
        path.removeEventListener('mouseout', handleMouseOut);
      });
    };
  }, [onPathHover]);



  const handleColorChange = (e) => {
    const newColor = e.target.value;
    onColorPropsChange({
      ...colorProps,
      strokeColor: newColor
    });
    setPathAttribute(selectedPath, newColor, colorProps.strokeOpacity);
  };

  const handleOpacityChange = (e) => {
    const newOpacity = e.target.value;
    onColorPropsChange({
      ...colorProps,
      strokeOpacity: parseFloat(newOpacity)
    });
    setPathAttribute(selectedPath, colorProps.strokeColor, newOpacity);
  };

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{ __html: svgCode }}
        className="svg-modal-container h-[32vh] md:h-[48vh]"
        ref={containerRef}
      />
      {selectedPath && (
        <div className="mt-4">
          <PathAttributeInput
            label="Select stroke color:"
            type="color"
            value={colorProps.strokeColor}
            onChange={handleColorChange}
          />
          <PathAttributeInput
            label="Opacity:"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={colorProps.strokeOpacity}
            onChange={handleOpacityChange}
          />
        </div>
      )}
    </div>
  );
}

function PathAttributeInput({ label, ...props }) {
  return (
    <label className="block my-2">
      {label}
      <input {...props} className="ml-2" />
    </label>
  );
}

function getAttributeWithFallback(target, attributes, fallback) {
  for (const attr of attributes) {
    if (target.hasAttribute(attr)) return target.getAttribute(attr);
  }
  return fallback;
}

function setPathAttribute(path, color, opacity) {
  if (path.hasAttribute('stroke')) {
    path.setAttribute('stroke', color);
    path.setAttribute('stroke-opacity', opacity);
  } else if (path.hasAttribute('fill')) {
    path.setAttribute('fill', color);
    path.setAttribute('fill-opacity', opacity);
  }
}

export default SvgComponent;
