import React, { useState } from 'react';
import { useAccessKey } from '../access/AccessKeyContext';
import './Premium.css'

const Premium = () => {
  const { isValidKey, validateKey } = useAccessKey();

  const [emailOrKey, setEmailOrKey] = useState('');
  const [isForgotClicked, setIsForgotClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleClick = () => {
    setErrorMessage('');
    setEmailOrKey('');
    setIsForgotClicked(!isForgotClicked);
  };

  const handleSubmission = async () => {
    setErrorMessage('');
    setSuccessMessage('');

    if (isForgotClicked) {
      const apiUrl = process.env.REACT_APP_BACKEND_API_URL
      const url = `${apiUrl}/api/email/forgot`
      const options = {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({ email: emailOrKey, url: window.location.href }),
        redirect: 'follow'
      };

      fetch(url, options)
        .then(response => response.text())
        .then(data => {
          setSuccessMessage('You should receive an email with access instructions.')
          setEmailOrKey('')
        })
        .catch(error => setErrorMessage('Something went wrong sending email instructions.'))
    } else {
      const isValid = await validateKey(emailOrKey);
      if (!isValid) {
        setErrorMessage('Invalid access key.');
      }
    }
  };

  return (
    <section className="text-gray-600 bg-squares">
      <div className="container px-5 py-12 mx-auto flex flex-col md:flex-row">
        <div className="w-full md:w-3/5 text-left md:pr-12">
          <div className="flex flex-col w-ful text-center md:text-left">
            <h2 className="text-xs text-orange-300 tracking-widest font-medium title-font mb-2">PREMIUM ACCESS</h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Unlock the full potential of SVG Emoji</h1>
            <p className="leading-relaxed text-base">
              Your premium access is a one-time investment. No recurring fees, no hidden charges.
            </p>
          </div>
          <button className="justify-center flex py-2 px-4 mt-6 font-bold text-black bg-lime-300 focus:outline-none hover:bg-lime-600 hover:text-white rounded text-lg w-full md:w-auto">
            Get lifetime premium access for $49
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mt-1 ml-2 w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
          </button>
          <div className="flex flex-wrap mt-8">
            <div class="my-4 px-4 border-l border-gray-300">
              <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font">Simplistic SVG editor</h2>
              <p class="leading-relaxed text-base mb-4">Tweak, adjust, and customize emojis to match your mood or message perfectly. No more fiddling with complex software. Our built-in editor ensures even a beginner can craft masterpieces.</p>
            </div>
            <div class="my-4 px-4 border-l border-gray-300">
              <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font">Export with ease</h2>
              <p class="leading-relaxed text-base mb-4">Download your customized SVG emojis in seconds, ready for any platform or purpose.</p>
            </div>
            <div class="my-4 px-4 border-l border-gray-300">
              <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font">Promise of the future</h2>
              <p class="leading-relaxed text-base mb-4">As we introduce new features, innovative editing tools, and unique emojis, you'll get them all - at no extra charge.</p>
            </div>
            <div class="my-4 px-4 border-l border-gray-300">
              <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font">Stay ahead, stay unique</h2>
              <p class="leading-relaxed text-base mb-4">In the digital age, personalization is key. Our premium access ensures you're always armed with fresh and distinctive emojis, setting you apart from the rest.</p>
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/5 flex flex-col text-center md:text-left">
          <div className="px-12 py-12 bg-white border borger-gray-200 rounded">
            <h2 className="text-xl font-bold text-blue-500">{!isForgotClicked ? 'Already have an access key?' : 'Enter email below.'}</h2>
            <div className="w-full mt-4">
              <input
                className="border w-full rounded outline-none py-3 px-3"
                placeholder={isForgotClicked ? "Enter your email" : "XXX-XXXX-XXXX-XXXX"}
                type={isForgotClicked ? "email" : "text"}
                value={emailOrKey}
                onChange={(e) => setEmailOrKey(e.target.value)}
              />
              <button
                className="mt-4 py-2 px-4 w-full md:w-auto font-bold text-black bg-gray-300 focus:outline-none hover:bg-gray -600 hover:text-white rounded"
                onClick={handleSubmission}
              >
                {isForgotClicked ? "Submit Email" : "Verify access key"}
              </button>
              {errorMessage && <p className="mt-4 text-rose-500">{errorMessage}</p>}
              {successMessage && <p className="mt-4 text-emerald-500">{successMessage}</p>}
              <div>
                <button className="text-sm text-gray-400 hover:text-orange-600 mt-8" onClick={handleClick}>{!isForgotClicked ?  'I forgot my access key.' : 'I have an access key.'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Premium;
