import React from "react";

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font border-t">
      <div className="container px-5 py-4 mx-auto flex items-center flex-row">
        <a className="flex title-font font-medium items-center justify-start text-gray-900" href="https://svgemoji.com">
          <svg viewBox="0 0 24 24" class="w-8 h-8 text-white p-1 bg-orange-500 rounded-full" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.39747 15.5534C8.64413 15.2206 9.11385 15.1508 9.44661 15.3975C10.175 15.9373 11.0541 16.25 12 16.25C12.9459 16.25 13.825 15.9373 14.5534 15.3975C14.8862 15.1508 15.3559 15.2206 15.6025 15.5534C15.8492 15.8862 15.7794 16.3559 15.4466 16.6025C14.4742 17.3233 13.285 17.75 12 17.75C10.715 17.75 9.5258 17.3233 8.55339 16.6025C8.22062 16.3559 8.15082 15.8862 8.39747 15.5534ZM16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5ZM9 12C9.55228 12 10 11.3284 10 10.5C10 9.67157 9.55228 9 9 9C8.44772 9 8 9.67157 8 10.5C8 11.3284 8.44772 12 9 12Z" fill="white"></path> </g></svg>
          <span className="ml-2 text-xl"><span className="font-light">svg</span>emoji</span>
        </a>
        <p className="text-sm text-gray-500 ml-4 border-gray-200 py-2 mt-0">
        © {new Date().getFullYear()} svgemoji <span className="hidden md:inline">—<a href="https://twitter.com/mitchbregs" className="text-gray-600 hover:text-lime-500 ml-1" rel="noopener noreferrer" target="_blank">@mitchbregs</a></span>
        </p>
        <span className="inline-flex mt-0 ml-auto">
      <a class="ml-3 text-blue-400 hover:text-blue-700">
        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
          <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
        </svg>
      </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
