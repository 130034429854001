import React from "react";

const CHECKOUT_URL = process.env.REACT_APP_STRIPE_CHECKOUT_URL

const Pricing = () => {

  return (
<section class="text-black body-font overflow-hidden">
  <div class="container px-5 py-4 mx-auto">
    <div class="flex flex-col text-center w-full mb-12">
      <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2">Pricing</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-black">Pay once and get access to all future updates.</p>
    </div>
    <div class="flex flex-wrap justify-center text-left">
      <div class="p-2 xl:w-1/2 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-lime-500 flex flex-col relative overflow-hidden">
          <span class="bg-lime-500 text-black px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
          <h1 class="text-5xl text-black leading-none flex items-center pb-4 mb-4 border-b border-orange-200">
            <span>$49</span>
            <span class="text-lg ml-2 font-normal text-gray-500">for lifetime access</span>
          </h1>
          <p class="flex items-center text-black mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-black rounded-full flex-shrink-0">
              <svg fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Customize emojis without limits
          </p>
          <p class="flex items-center text-black mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-black rounded-full flex-shrink-0">
              <svg fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Fine-tune stroke and fill details
          </p>
          <p class="flex items-center text-black mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-black rounded-full flex-shrink-0">
              <svg fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Unlimited emoji downloads for web projects
          </p>
          <p class="flex items-center text-black mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-600 text-black rounded-full flex-shrink-0">
              <svg fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>Access to all future updates
          </p>
          <a href={CHECKOUT_URL} class="flex font-bold items-center mt-auto text-black bg-lime-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-lime-600 hover:text-white rounded">
            Get lifetime access
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <p class="text-xs text-gray-500 mt-3">You would be crazy to pass up on this offer.</p>
        </div>
      </div>
    </div>
  </div>
</section>
  );
};

export default Pricing;
