import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { adjustColor } from "../utils";

import { Header } from "./DemoPreview/Header";
import { Filters } from "./DemoPreview/Filters";
import { ItemList } from "./DemoPreview/ItemList";
import { Pagination } from "./DemoPreview/Pagination";


const Demo = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const INITIAL_STATE = {
    isLoading: true,
    svgList: [],
    svgCurrentPage: searchParams.get("page") || 1,
    svgTotalPages: 0,
    svgPerPage: 60,
    svgCount: 0,
    svgTotal: 0,
    searchTerm: searchParams.get("search") || "",
    color: searchParams.get("color") || "#FFDE34",
    isColored: false,
  };

  const [state, setState] = useState(INITIAL_STATE);

  const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

  const fetchSVGData = (page, svgPerPage, searchTerm, loadingState) => {
    setState(prev => ({ ...prev, isLoading: loadingState }));
    
    const searchParam = searchTerm ? `&search=${searchTerm}` : ''
    const url = `${apiUrl}/api/svg/?page=${page}&limit=${svgPerPage}${searchParam}`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setState(prev => ({
          ...prev,
          svgList: data.results,
          svgCurrentPage: data.current_page,
          svgTotalPages: data.total_pages,
          svgCount: data.count,
          svgTotal: data.total,
          isLoading: false,
        }));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setState(prev => ({ ...prev, isLoading: null }));
      });
  };

  useEffect(() => {
    fetchSVGData(state.svgCurrentPage, state.svgPerPage, state.searchTerm, true);
  }, []);

  const loadPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= state.svgTotalPages) {
      fetchSVGData(pageNumber, state.svgPerPage, state.searchTerm, true);
    }
  };

  const setSVGAttributes = (svgContainers, attributeFunction) => {
    svgContainers.forEach((container) => {
      const paths = container.querySelectorAll('path, circle, rect, line, ellipse');
      paths.forEach(attributeFunction);
    });
  };
  const randomizeColors = () => {
    setState(prev => ({ ...prev, color: 'random', isColored: true }));

    const svgContainers = document.querySelectorAll('.svg-container');
    const setRandomColor = (path) => {
      const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      path.setAttribute(path.hasAttribute('data-original-stroke') ? 'stroke' : 'fill', randomColor);
    };
    setSVGAttributes(svgContainers, setRandomColor);
  };

  const handleColorChange = (e) => {
    const targetColor = e.target.value;
    setState(prev => ({ ...prev, color: targetColor, isColored: true }));

    const svgContainers = document.querySelectorAll('.svg-container');
    const setColor = (path) => {
      const adjustedColor = adjustColor(path.getAttribute(path.hasAttribute('data-original-stroke') ? 'data-original-stroke-grayscale' : 'data-original-fill-grayscale'), targetColor);
      path.setAttribute(path.hasAttribute('data-original-stroke') ? 'stroke' : 'fill', adjustedColor);
    };
    setSVGAttributes(svgContainers, setColor);
  };

  const resetColors = () => {
    setState(prev => ({ ...prev, color: "#FFDE34", isColored: false }));

    const svgContainers = document.querySelectorAll('.svg-container');
    const resetColor = (path) => {
      path.setAttribute(path.hasAttribute('data-original-stroke') ? 'stroke' : 'fill', path.getAttribute(path.hasAttribute('data-original-stroke') ? 'data-original-stroke' : 'data-original-fill'));
    };
    setSVGAttributes(svgContainers, resetColor);
  };

  const handleSearchTermChange = (event) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, searchTerm: value }));
  };

  const handleSearchButtonClick = () => {
    setState((prev) => ({ ...prev, color: "#FFDE34", isColored: false }));
    fetchSVGData(state.svgCurrentPage, state.svgPerPage, state.searchTerm, true);
  };
  
  const resetSearch = () => {
    setState((prev) => ({ ...prev, searchTerm: "" }));
    fetchSVGData(state.svgCurrentPage, state.svgPerPage, "", true);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto flex flex-wrap">
        <Header />
        <Filters
          color={state.color}
          resetColors={resetColors}
          randomizeColors={randomizeColors}
          handleColorChange={handleColorChange}
          isColored={state.isColored}
          searchTerm={state.searchTerm}
          handleSearchTermChange={handleSearchTermChange}
          handleSearchButtonClick={handleSearchButtonClick}
          isLoading={state.isLoading}
          resetSearch={resetSearch}
        />
        <ItemList
          items={state.svgList}
          isLoading={state.isLoading}
        />
        <Pagination
          currentPage={state.svgCurrentPage}
          totalPages={state.svgTotalPages}
          loadPage={loadPage}  // Pass the generic loadPage function to handle page changes
        />
      </div>
    </section>
  );
};

export default Demo;
