export function Header() {

  return (
    <div className="flex w-full my-6 md:my-12 flex-wrap text-left">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/6 lg:mb-0 mb-4">
        Emojis
      </h1>
      <p className="lg:pl-6 lg:w-5/6 mx-auto leading-relaxed text-base">
        Crafting pixel-perfect icons for your web projects just got easier. Explore our collection of customizable SVG emojis designed with web designers in mind. From precise RGB and HSL adjustments to stroke and fill control, bring a new level of expression to your designs.
      </p>
    </div>
  );
}
